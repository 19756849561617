import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { CommentaryWebSocketContext } from 'context/commentary-websocket';

export const CommentaryAudioContext = createContext();

export const CommentaryAudioProvider = ({ children }) => {
  const commentaryWebSocketContext = useContext(CommentaryWebSocketContext);
  const { lastMessage } = commentaryWebSocketContext;
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isTabActive, setIsTabActive] = useState(!document.hidden);
  const audioElemRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);

      // Pause audio when tab becomes inactive
      if (document.hidden && audioElemRef.current) {
        audioElemRef.current.pause();
        setIsPlaying(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (lastMessage?.data) {
      setAudioQueue((prevQueue) => [...prevQueue, lastMessage.data]);
    }
  }, [lastMessage]);

  useEffect(() => {
    const playNextAudio = async () => {
      // Only proceed if tab is active and there's audio to play
      if (audioQueue.length === 0 || isPlaying || !isTabActive) return;

      const audioData = audioQueue[0];
      try {
        setIsLoading(true);
        const blob = new Blob([audioData], { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);

        const audio = new Audio(url);
        audioElemRef.current = audio;

        audio.oncanplaythrough = () => {
          // Double check tab is still active before playing
          if (isTabActive) {
            setIsLoading(false);
            setIsPlaying(true);
            audio.play();
          } else {
            // Clean up if tab became inactive during loading
            URL.revokeObjectURL(url);
            setIsLoading(false);
            audio.remove();
            audioElemRef.current = null;
          }
        };

        audio.onended = () => {
          setIsPlaying(false);
          URL.revokeObjectURL(url);
          setAudioQueue((prevQueue) => prevQueue.slice(1));
          audio.remove();
          audioElemRef.current = null;
        };

        audio.onerror = (err) => {
          console.error('Audio playback error:', err);
          setIsPlaying(false);
          setIsLoading(false);
          URL.revokeObjectURL(url);
          setAudioQueue((prevQueue) => prevQueue.slice(1));
          audio.remove();
          audioElemRef.current = null;
          setError('Error during audio playback.');
        };
      } catch (err) {
        console.error('Error processing audio:', err);
        setIsPlaying(false);
        setIsLoading(false);
        setAudioQueue((prevQueue) => prevQueue.slice(1));
        setError('Error processing audio data.');
      }
    };

    if (!isPlaying && audioQueue.length > 0 && isTabActive) {
      playNextAudio();
    }
  }, [audioQueue, isPlaying, isTabActive]);

  useEffect(() => {
    return () => {
      if (audioElemRef.current) {
        audioElemRef.current.pause();
        audioElemRef.current.remove();
      }
    };
  }, []);

  return (
    <CommentaryAudioContext.Provider value={{ isPlaying, isLoading, error, isTabActive }}>
      {children}
    </CommentaryAudioContext.Provider>
  );
};

export const useCommentaryAudio = () => {
  const context = useContext(CommentaryAudioContext);
  if (context === undefined) {
    throw new Error('useCommentaryAudio must be used within a CommentaryAudioProvider');
  }
  return context;
};
