import React, { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import Loader from 'components_v3/Loader';
import { NotFound } from 'components_v3/NotFound';
import { PrivateRoute } from 'components_v3/PrivateRoute';
import { RapidOnboarding } from 'components_v3/RapidOnboarding';
import {
  ACCOUNT_SETTINGS_PATH,
  COLLABORATED_CONVERSATION,
  COLLABORATED_ROUTINE,
  CONTINUE_SNAPSHOT_PATH,
  CONVERSATION_LIST_PATH,
  CONVERSATION_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  MY_COLLABORATIONS_PATH,
  MY_PROFILE_PATH,
  PASSWORD_CHANGE_PATH,
  PASSWORD_SENT_PATH,
  PATH_AUTH_ERROR,
  PATH_SIGN_IN,
  RAPID_ONBOARDING_PATH,
  ROUTINE_LIST_PATH,
  ROUTINE_PATH,
  SNAPSHOT_PATH,
} from 'constants/routes';
import {
  FeaturesProvider,
  ChatWebSocketProvider,
  CommentaryWebSocketProvider,
  CommentaryAudioProvider,
} from 'context';

import PublicSnapshot from 'pages_v3/PublicSnapshot';
import { getSubdomain, isUserSignInPage, testableSignInPagePath } from 'utils/utils';

const AuthError = React.lazy(() =>
  import(/* webpackChunkName: "auth-error" */ 'pages_v3/AuthError'),
);
const ContinueSnapshotConversationPage = React.lazy(() =>
  import(/* webpackChunkName: "continue-snapshot" */ 'pages_v3/ContinueSnapshotConversation').then(
    (module) => ({ default: module.ContinueSnapshotConversationPage }),
  ),
);
const Home = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages_v3/Home'));
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ 'pages_v3/Login'));
const LoginSubdomain = React.lazy(() =>
  import(/* webpackChunkName: "login-subdomain" */ 'pages_v3/LoginSubdomain').then((module) => ({
    default: module.LoginSubdomain,
  })),
);
const UpdatePassword = React.lazy(() =>
  import(/* webpackChunkName: "update-password" */ 'pages_v3/UpdatePassword'),
);
const ForgotPassword = React.lazy(() =>
  import(/* webpackChunkName: "forgot-password" */ 'pages_v3/ForgotPassword'),
);
const PasswordSent = React.lazy(() =>
  import(/* webpackChunkName: "password-sent" */ 'pages_v3/PasswordSent'),
);
const ViewCollaboratedConversation = React.lazy(() =>
  import(
    /* webpackChunkName: "collaborated-conversation" */ 'pages_v3/ViewCollaboratedConversation'
  ).then((module) => ({ default: module.ViewCollaboratedConversation })),
);
const ViewCollaboratedRoutine = React.lazy(() =>
  import(/* webpackChunkName: "collaborated-routine" */ 'pages_v3/ViewCollaboratedRoutine').then(
    (module) => ({ default: module.ViewCollaboratedRoutine }),
  ),
);

const AppRoutesV2 = () => {
  const isShareSubdomain = getSubdomain() === 'share';

  const routesConfig = isShareSubdomain
    ? [
        {
          path: SNAPSHOT_PATH,
          element: (
            <Suspense fallback={<Loader />}>
              <PublicSnapshot />
            </Suspense>
          ),
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ]
    : [
        ...[
          HOME_PATH,
          CONVERSATION_PATH,
          CONVERSATION_LIST_PATH,
          ROUTINE_PATH,
          ROUTINE_LIST_PATH,
          MY_PROFILE_PATH,
          MY_COLLABORATIONS_PATH,
        ].map((path) => ({
          path,
          element: isUserSignInPage() ? (
            <Navigate to={testableSignInPagePath()} />
          ) : (
            <PrivateRoute>
              <ChatWebSocketProvider>
                <CommentaryWebSocketProvider>
                  <CommentaryAudioProvider>
                    <FeaturesProvider>
                      <Suspense fallback={<Loader />}>
                        <Home />
                      </Suspense>
                    </FeaturesProvider>
                  </CommentaryAudioProvider>
                </CommentaryWebSocketProvider>
              </ChatWebSocketProvider>
            </PrivateRoute>
          ),
        })),
        {
          path: ACCOUNT_SETTINGS_PATH,
          element: <Navigate to={MY_PROFILE_PATH} />,
        },
        {
          path: RAPID_ONBOARDING_PATH,
          element: <RapidOnboarding />,
        },
        {
          path: COLLABORATED_CONVERSATION,
          element: (
            <PrivateRoute>
              <FeaturesProvider>
                <Suspense fallback={<Loader />}>
                  <ViewCollaboratedConversation />
                </Suspense>
              </FeaturesProvider>
            </PrivateRoute>
          ),
        },
        {
          path: COLLABORATED_ROUTINE,
          element: (
            <PrivateRoute>
              <FeaturesProvider>
                <Suspense fallback={<Loader />}>
                  <ViewCollaboratedRoutine />
                </Suspense>
              </FeaturesProvider>
            </PrivateRoute>
          ),
        },
        {
          path: CONTINUE_SNAPSHOT_PATH,
          element: (
            <PrivateRoute>
              <FeaturesProvider>
                <Suspense fallback={<Loader />}>
                  <ContinueSnapshotConversationPage />
                </Suspense>
              </FeaturesProvider>
            </PrivateRoute>
          ),
        },
        {
          path: PATH_AUTH_ERROR,
          element: (
            <Suspense fallback={<Loader />}>
              <AuthError />
            </Suspense>
          ),
        },
        {
          path: PATH_SIGN_IN,
          element: isUserSignInPage() ? (
            <Suspense fallback={<Loader />}>
              <LoginSubdomain />
            </Suspense>
          ) : (
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          ),
        },
        {
          path: PASSWORD_CHANGE_PATH,
          element: (
            <Suspense fallback={<Loader />}>
              <UpdatePassword />
            </Suspense>
          ),
        },
        {
          path: FORGOT_PASSWORD_PATH,
          element: (
            <Suspense fallback={<Loader />}>
              <ForgotPassword />
            </Suspense>
          ),
        },
        {
          path: PASSWORD_SENT_PATH,
          element: (
            <Suspense fallback={<Loader />}>
              <PasswordSent />
            </Suspense>
          ),
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ];

  const routes = useRoutes(routesConfig);

  return routes;
};

export default AppRoutesV2;
