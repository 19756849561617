import { PropTypes } from 'prop-types';
import React, { createContext } from 'react';
import useWebSocket from 'react-use-websocket';

import { WS_CHAT } from 'constants/constants';
import { buildUrl } from 'utils/utils';

const MAX_RECONNECT_INTERVAL = 10000;

export const ChatWebSocketContext = createContext();

export const ChatWebSocketProvider = ({ children }) => {
  const url = buildUrl(WS_CHAT).replace('http', 'ws');

  const websocket = useWebSocket(url, {
    shouldReconnect: () => true,
    reconnectAttempts: Number.MAX_SAFE_INTEGER,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, MAX_RECONNECT_INTERVAL),
    retryOnError: true,
  });

  return (
    <ChatWebSocketContext.Provider value={websocket}>{children}</ChatWebSocketContext.Provider>
  );
};

ChatWebSocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
