export const MARKDOWN_MENTION_LINK_PREFIX = '##mention##/';

export const escapeHtml = (text) => {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export const addMentionMarkup = (content, participants, markdownView = false) => {
  if (!content) return '';

  let result = escapeHtml(content);
  participants.forEach((participant) => {
    const escapedName = participant.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(
      `(^|[ \\t\\n\\r\\u2000-\\u200A\\u202F\\u205F\\u3000])@${escapedName}`,
      'g',
    );
    result = markdownView
      ? result.replace(
        regex,
        (match, p1) => {
          return `${p1}[@${participant.name}](${MARKDOWN_MENTION_LINK_PREFIX}${encodeURIComponent(participant.name)})`;
        },
      )
      : result.replace(
        regex,
        (match, p1) => {
          return `${p1}<span class="mention">@${participant.name}</span>`;
        },
      );
  });

  return result;
};
