import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { toLanguageCode } from 'i18n';


const LogoComponent = ({ logoUrl, altText }) => {
  const { i18n } = useTranslation();

  return (
    <a
      href={`${import.meta.env.VITE_CC_URL}${toLanguageCode(i18n.language)}`}
      target='_blank'
      rel='noreferrer'>
      <img className='company-logo' src={logoUrl} alt={altText} />
    </a>
  );
};

const Logo = memo(LogoComponent);
Logo.displayName = 'Logo';

Logo.propTypes = {
  logoUrl: PropTypes.string,
  altText: PropTypes.string.isRequired,
};

LogoComponent.propTypes = {
  logoUrl: PropTypes.string,
  altText: PropTypes.string.isRequired,
};

export { Logo };
