import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import { API_UPDATE_CONVERSATION_VIEW_SETTINGS } from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { conversationKeys } from 'hooks/conversations';
import { buildUrl } from 'utils/utils';

const SNAPSHOT_SETTINGS_KEY = 'snapshotViewSettings';

const getSnapshotViewSettings = (snapshotId, conversationViewSettings) => {
  const allSettings = JSON.parse(localStorage.getItem(SNAPSHOT_SETTINGS_KEY) || '{}');
  return {
    ...conversationViewSettings,
    ...allSettings[snapshotId],
  };
};

const saveSnapshotViewSettings = (snapshotId, settings) => {
  const allSettings = JSON.parse(localStorage.getItem(SNAPSHOT_SETTINGS_KEY) || '{}');
  allSettings[snapshotId] = settings;
  localStorage.setItem(SNAPSHOT_SETTINGS_KEY, JSON.stringify(allSettings));
};

export const useViewSettingsMutation = (conversationId) => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async (newSettings) => {
      const response = await apiClient(
        buildUrl(API_UPDATE_CONVERSATION_VIEW_SETTINGS, [{ name: 'id', value: conversationId }]),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            view_settings: {
              ...newSettings.viewSettings,
            },
            explicit_url_detection: newSettings.explicitUrlDetection,
            commentary_enabled: newSettings.commentaryEnabled,
          }),
        },
      );
      if (!response.ok) throw Error(`Save settings failed: ${response.json()}`);
      return response.json();
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(conversationKeys.detail(conversationId), (oldData) => {
          return {
            ...oldData,
            view_settings: {
              ...oldData.view_settings,
              ...data.view_settings,
            },
          };
        });
      },
    },
  );
};

export const useSnapshotViewSettingsQuery = (snapshotId, conversationViewSettings) => {
  return useQuery(
    [SNAPSHOT_SETTINGS_KEY, snapshotId],
    () => getSnapshotViewSettings(snapshotId, conversationViewSettings),
    {
      staleTime: Infinity,
      initialData: () => getSnapshotViewSettings(snapshotId, conversationViewSettings),
    },
  );
};

export const useSnapshotViewSettingsMutation = (snapshotId, conversationViewSettings) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newSettings) => {
      const currentSettings = getSnapshotViewSettings(snapshotId, conversationViewSettings);
      const updatedSettings = { ...currentSettings, ...newSettings };
      saveSnapshotViewSettings(snapshotId, updatedSettings);
      return updatedSettings;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData([SNAPSHOT_SETTINGS_KEY, snapshotId], data);
      },
    },
  );
};
