import { Checkmark, Close, CopyFile, Edit, Renew } from '@carbon/icons-react';
import { Accordion, AccordionItem, Button, TextArea, Tooltip } from '@carbon/react';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

import BrokenImg from 'assets/broken_img.svg';
import DefaultModelAvatar from 'assets/v3/app_logo.png';
import { ReactComponent as ViewModeIcon } from 'assets/view_mode_icon.svg';
import { CodeBlock } from 'components_v3/markdown/code-block';
import { MemoizedReactMarkdown } from 'components_v3/markdown/memoized-react-markdown';
import { useCopyToClipboard } from 'hooks/conversations';
import { useNoneToolModels } from 'hooks/tools';
import { prepareLatexContent } from 'utils/latexPreprocessor';
import { displayMessageTime } from 'utils/message';

import PopoverWrapper from './PopoverWrapper';
import { RespondingIndicator } from './RespondingIndicator';

import './ConversationMessageAssistant.scss';

const ConversationMessageAssistant = ({
  message,
  streamingResponse,
  isError,
  snapshot_llms = [],
  onRegenerate = () => {},
  shouldShowRegenerateButton = () => {},
  onEditMessage,
  isConversationOwner,
  previousMessage,
  currentUser,
  markdownView,
  latexView,
}) => {
  const { t, i18n } = useTranslation();
  const { isCopied, handleCopyToClipboard } = useCopyToClipboard();
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(message.content);

  const onRegenerateClick = () => {
    onRegenerate();
  };

  const noneToolModels = snapshot_llms.length === 0 ? useNoneToolModels() : null;

  const allModels =
    snapshot_llms.length > 0
      ? snapshot_llms
      : useMemo(() => {
          return get(noneToolModels, ['data'], []);
        }, [snapshot_llms, noneToolModels]);

  const assistantModel = useMemo(
    () => find(allModels, (model) => model.id === message.model),
    [message, allModels],
  );

  const canEditMessage = useMemo(() => {
    return isConversationOwner || (previousMessage && previousMessage.sender?.id === currentUser);
  }, [isConversationOwner, previousMessage, currentUser]);

  const handleEditMessage = () => {
    setIsEditing(true);
    setEditedContent(message.content);
  };

  const handleSaveEdit = () => {
    onEditMessage(message.id, editedContent);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditedContent(message.content);
    setIsEditing(false);
  };

  const calculateRows = (text) => {
    const lineCount = text.split('\n').length;
    return Math.max(3, Math.min(20, lineCount)); // Minimum 3 rows, maximum 20 rows
  };

  return (
    <>
      <header className='message-header'>
        <div className='header-content'>
          <PopoverWrapper
            className='avatar-wrapper'
            content={
              <div className='avatar-popover'>
                {assistantModel?.display_name || t('SkyDeck.ai')}
              </div>
            }>
            <img
              className='avatar'
              src={assistantModel?.icon || DefaultModelAvatar}
              alt={t('Bot')}
            />
          </PopoverWrapper>
          <div className='message-info'>
            <span className='message-info__sender-name'>
              {assistantModel?.display_name || t('SkyDeck.ai')}
            </span>
            {message.timestamp && (
              <span className='message-info__time'>
                {displayMessageTime(message.timestamp, i18n.language)}
                {message.edited_at && <span> </span>}
                {message.edited_at && (
                  <Tooltip
                    className='edited-tooltip'
                    align='bottom'
                    label={t('{{time}}', {
                      time:
                        displayMessageTime(message.edited_at, i18n.language)
                          .charAt(0)
                          .toUpperCase() +
                        displayMessageTime(message.edited_at, i18n.language).slice(1),
                    })}>
                    <span className='edited-indicator'>{'(edited)'}</span>
                  </Tooltip>
                )}
              </span>
            )}
          </div>
        </div>
        <div className='actions'>
          {shouldShowRegenerateButton(message.id) && !isEditing && (
            <Button
              as='block'
              kind='ghost'
              size='sm'
              className='regenerate-button'
              renderIcon={Renew}
              hasIconOnly
              onClick={onRegenerateClick}
              tooltipPosition='bottom'
              iconDescription={t('Regenerate')}
            />
          )}
          {canEditMessage && !isEditing && (
            <Button
              as='block'
              onClick={handleEditMessage}
              className='edit-button'
              hasIconOnly
              renderIcon={Edit}
              iconDescription={t('Edit message')}
              tooltipPosition='bottom'
              kind='ghost'
              disabled={isEditing}
            />
          )}
          {isEditing ? (
            <>
              <Button
                as='block'
                onClick={handleCancelEdit}
                className='cancel-button'
                hasIconOnly
                renderIcon={Close}
                iconDescription={t('Cancel edit')}
                tooltipPosition='bottom'
                kind='ghost'
              />
              <Button
                as='block'
                onClick={handleSaveEdit}
                className='save-button'
                hasIconOnly
                renderIcon={Checkmark}
                iconDescription={t('Save edit')}
                tooltipPosition='bottom'
                kind='ghost'
              />
            </>
          ) : (
            <Button
              as='block'
              onClick={() => handleCopyToClipboard(message.content)}
              className='copy-button'
              hasIconOnly
              renderIcon={isCopied ? Checkmark : CopyFile}
              iconDescription={t('Copy text')}
              tooltipPosition='bottom'
              kind='ghost'
            />
          )}
        </div>
      </header>
      <div className='content'>
        <div className='conversation-message-assistant-container'>
          {streamingResponse.length !== 0 || message.streaming === false ? (
            <></>
          ) : isError ? (
            <div>
              <i>{t('Something went wrong. Try again.')}</i>
            </div>
          ) : (
            <div>
              <RespondingIndicator />
            </div>
          )}
          <div className='text' key={streamingResponse}>
            {isEditing ? (
              <TextArea
                id={`edit-message-${message.id}`}
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
                labelText={t('Edit message')}
                placeholder={t('Edit message')}
                className='edit-textarea'
                rows={calculateRows(editedContent)}
              />
            ) : markdownView ? (
              <MemoizedReactMarkdown
                key={latexView}
                remarkPlugins={[remarkGfm, ...(latexView ? [remarkMath, remarkBreaks] : [])]}
                escapeHtml={false}
                rehypePlugins={[rehypeRaw, ...(latexView ? [rehypeKatex] : [])]}
                linkTarget='_blank'
                components={{
                  img: ({ node }) => {
                    const prop = node.properties;
                    return (
                      <img
                        className='generated-image'
                        src={prop.src}
                        alt={prop.alt}
                        onError={(event) => {
                          event.target.src = BrokenImg;
                        }}
                      />
                    );
                  },
                  code({ inline, className, children, ...props }) {
                    if (children && children.length) {
                      if (children[0] == '▍') {
                        return <span>▍</span>;
                      }

                      children[0] = children[0].replace('▍', '▍');
                    }

                    const match = /language-(\w+)/.exec(className || '');

                    return !inline ? (
                      <CodeBlock
                        key={Math.random()}
                        language={(match && match[1]) || ''}
                        value={String(children).replace(/\n$/, '')}
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  details: ({ children }) => {
                    const [open, setOpen] = useState(false);
                    const summaryElement = children?.find((child) => child?.type === 'summary');

                    const summaryText = summaryElement?.props?.children || t('Summary');

                    return (
                      <Accordion>
                        <AccordionItem
                          title={summaryText}
                          onHeadingClick={({ isOpen }) => {
                            setOpen(isOpen);
                          }}>
                          {children?.filter((child) => child?.type !== 'summary')}
                        </AccordionItem>
                      </Accordion>
                    );
                  },
                }}>
                {latexView
                  ? prepareLatexContent(message.streaming ? streamingResponse : message.content)
                  : message.streaming
                  ? streamingResponse
                  : message.content}
              </MemoizedReactMarkdown>
            ) : (
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {message.streaming ? streamingResponse : message.content}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ConversationMessageAssistant.propTypes = {
  message: PropTypes.object.isRequired,
  streamingResponse: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  snapshot_llms: PropTypes.array,
  onRegenerate: PropTypes.func.isRequired,
  shouldShowRegenerateButton: PropTypes.func.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  isConversationOwner: PropTypes.bool.isRequired,
  previousMessage: PropTypes.object,
  currentUser: PropTypes.number.isRequired,
  markdownView: PropTypes.bool.isRequired,
  latexView: PropTypes.bool.isRequired,
  onBetterFormatClick: PropTypes.func.isRequired,
};

export default ConversationMessageAssistant;

export const ConversationMessageAssistantPlaceholder = ({ assistantModel }) => {
  const { t } = useTranslation();

  return (
    <li className='message assistant-message'>
      <div className='message-wrapper'>
        <header className='message-header'>
          <div className='header-content'>
            <PopoverWrapper
              className='avatar-wrapper'
              content={
                <div className='avatar-popover'>
                  {assistantModel?.display_name || t('SkyDeck.ai')}
                </div>
              }>
              <img
                className='avatar'
                src={assistantModel?.icon || DefaultModelAvatar}
                alt={t('Bot')}
              />
            </PopoverWrapper>
            <div className='message-info'>
              <span className='message-info__sender-name'>
                {assistantModel?.display_name || t('SkyDeck.ai')}
              </span>
              <span className='message-info__time'>{t('Processing...')}</span>
            </div>
          </div>
        </header>
        <div className='content'>
          <div className='conversation-message-assistant-container'>
            <div>
              <RespondingIndicator />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

ConversationMessageAssistantPlaceholder.propTypes = {
  assistantModel: PropTypes.object,
};
