import { initializeApp } from 'firebase/app';
import { PropTypes } from 'prop-types';
import React, { createContext } from 'react';

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE);

  const app = initializeApp(firebaseConfig);

  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>;
};

FirebaseProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
