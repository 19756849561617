import { formatRelative, parseISO } from 'date-fns';
import { enUS, fr, da, ja, ar, ko, de, zhCN, zhHK, es, pt, vi } from 'date-fns/locale';


import {
  GOOGLE_LOGIN_DISABLED,
  INVALID_TOKEN_ERROR,
  MICROSOFT_LOGIN_DISABLED,
  PASSWORD_LOGIN_DISABLED,
} from 'constants/constants';

const localeMap = {
  en: enUS,
  fr: fr,
  da: da,
  ja: ja,
  ar: ar,
  ko: ko,
  de: de,
  'zh-cn': zhCN,
  'zh-hk': zhHK,
  es: es,
  pt: pt,
  vi: vi,
};

const displayMessageTime = (isoTimestamp, locale) =>
  formatRelative(parseISO(isoTimestamp), new Date(), {
    locale: localeMap[locale] || enUS,
  });

const getAuthErrorMessage = (authError) => {
  switch (authError) {
    case 'subscription_issue':
      return String.raw`There is a problem with your company's subscription plan. Please reach out to your company's account administrator for assistance.`;
    case 'uninvited':
      return 'You do not have access to this workspace. Please check if you have selected the correct workspace or contact your administrator for assistance.';
    default:
      return 'Something went wrong. Unable to sign in. Please try again later.';
  }
};

const getLoginErrorMessage = (error) => {
  switch (error) {
    case INVALID_TOKEN_ERROR:
      return 'This link has expired or is invalid. Please request a new invitation or password reset.';
    case PASSWORD_LOGIN_DISABLED:
      return 'Password login is currently disabled for this account. Please use an alternative login method or contact your administrator to enable password authentication.';
    case GOOGLE_LOGIN_DISABLED:
      return 'Google login is currently disabled for this account. Please use an alternative login method or contact your administrator to enable Google authentication.';
    case MICROSOFT_LOGIN_DISABLED:
      return 'Microsoft login is currently disabled for this account. Please use an alternative login method or contact your administrator to enable Microsoft authentication.';
    default:
      return 'Something went wrong.';
  }
};

export { displayMessageTime, getAuthErrorMessage, getLoginErrorMessage };
